import { template as template_45b416c39a8241c4a8ce4d248cb13721 } from "@ember/template-compiler";
const SidebarSectionMessage = template_45b416c39a8241c4a8ce4d248cb13721(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_2bddcaec0a8644cf93a5b7cf6555b5c9 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_2bddcaec0a8644cf93a5b7cf6555b5c9(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;

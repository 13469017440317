import { template as template_0fa96fe8c69f4cd0b5aa0ae54fe2b5cc } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_0fa96fe8c69f4cd0b5aa0ae54fe2b5cc(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}

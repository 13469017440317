import { template as template_f9ae674a4dae4ed8b9e08fb1424d0178 } from "@ember/template-compiler";
const WelcomeHeader = template_f9ae674a4dae4ed8b9e08fb1424d0178(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

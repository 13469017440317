import { template as template_3c5a1ed41abf493a8bf5a6e2e5d9c67d } from "@ember/template-compiler";
const FKLabel = template_3c5a1ed41abf493a8bf5a6e2e5d9c67d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
